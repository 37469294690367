import React, { useState, useEffect } from "react";
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import "../Pos/pos.css";
import { useCart } from '../../talons/useCustom';
import { Store } from '../../Store';
import Loader from "../Loader/Loader";

const CUSTOMER_LIST = gql`
			query getCustomerList($customer: String!) {
				customerList(customer: $customer) {
						data {
							id
                            firstname
							lastname
							email
							contact_number
					}
				}
			}
        `;

const CUSTOMERTOKEN_MUTATION = gql`
		mutation customerToken($customerId: String!) {
			customerToken(customerId:$customerId) {
				token
				quote_id
		  }
		}
		`;

const CustomerSearch = (props) => {
    const [refreshPage, setRefreshPage] = useState(false);
    const { state, dispatch } = React.useContext(Store);
    
    if(state.checkOutSuccess){
        localStorage.removeItem('customerName');
        localStorage.removeItem('customerId');
        localStorage.removeItem('customerToken');
        if(refreshPage !== true){
            setRefreshPage(true);
        }
       
        dispatch({
            type: 'checkout_success',
            payload: false
          });
    }

    useEffect(() => {
        document.addEventListener("click", handleClick);
        
        return () => {
            document.removeEventListener("click", handleClick);
        };
      });
    let customerCount = 0;
    const { getCutomerToken } = useCart({ 'CUSTOMERTOKEN_MUTATION': CUSTOMERTOKEN_MUTATION });
    const { placeholder, noItemFound } = props;
    const [searchValue, setSearchValue] = useState("");
    const [searchKey, setSearchKey] = useState(false);
    const [suggestion, setSuggestion] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    let currentCustomer = localStorage.getItem('customerName') ? localStorage.getItem('customerName') : 'Set Customer';
   
    const [customer, setCustomer] = useState("Set Customer");
    if(customer != currentCustomer)
        setCustomer(currentCustomer);
  
    let datavariables = { customer: searchValue };
    const { data,refetch } = useQuery(CUSTOMER_LIST, {
        variables: datavariables
    });
    if (typeof data != "undefined") {
        customerCount = data.customerList.data.length;
    }

    const getCustomerSuggetion = (e) => {
        if(e.target.value){
            setSearchKey(true);
        }else{
            setSearchKey(false);
        }
        
        setSearchValue(e.target.value);
        setSuggestion(true);
    }
    
    if(state.customerUpdate){
        refetch();
    }

    const handleClick = (e) => {
        
        if (e.target.className === "cross-img" || e.target.className === "search-img" || e.target.id === "customersearch" || e.target.name === "customersearch" || e.target.id === "search-span" || e.target.id === "search-icon") {
            setSuggestion(true);
        } else {
            setSuggestion(false);
        }
    }
    const showSuggestion = () => {
        setSearchValue("");
        setSuggestion(true);
    }
    const setCustomerData = async(customer) => {
        setShowLoader(true);
        let customerDetails = {
            "id" : customer.id,
            "firstname" : customer.firstname,
            "lastname" : customer.lastname,
            "email" : customer.email,
            "contact_number" : customer.contact_number
        };
        setCustomer(customer.firstname + " " + customer.lastname);
        localStorage.removeItem('customerToken');
        localStorage.removeItem('quote_id');
        localStorage.removeItem('cartDetails');
        localStorage.setItem('customerId', customer.id);
        localStorage.setItem('customerName', customer.firstname + " " + customer.lastname);
        localStorage.setItem('customerDetail', JSON.stringify(customerDetails));
        await getCutomerToken({ 'customerId': customer.id });
        setShowLoader(false);
    }
    
    return (
        <div  className="customer_search_wrap" >
            {showLoader && (
                <Loader />
            )}
            <div className="d-flex justify-content-between" onClick={showSuggestion} id="customersearch">
                <span id="search-span">{customer}</span>
                <img id="search-icon" className="mt-2" alt="no-item" src="images/caret-down.png" width="9" height="9" />
            </div>
            {suggestion && (
                <div className="customer_search position-relative">
                    <input className="mb-1" name="customersearch" id="search-field" placeholder={placeholder} onKeyUp={getCustomerSuggetion} />
                    {!searchKey && (
                        <img className="search-img" src="images/item-search.png" alt="search" width="15" height="15" />
                    )}
                    {searchKey && (
                        <img className="customer-cross-img" src="images/cancel.png" alt="cross" width="15" height="15" onClick={(e) => { 
                            document.getElementById('search-field').value="";
                            setSearchKey(false);
                    }}/>
                    )}
                    {typeof data != 'undefined' && suggestion && (
                        <ul className="set-cutomer-list p-2">
                            {data && data.customerList.data.map((customer, index) => {
                                var name = customer.firstname + " " + customer.lastname
                                if (customer.contact_number) {
                                    name += " - " + customer.contact_number;
                                }
                                return (
                                    <li key={index} onClick={() => setCustomerData(customer)} id={customer.id} value={name}>{name}</li>
                                );
                            })}
                        </ul>
                    )}
                    {customerCount === 0 && (
                        <ul className="set-cutomer-list p-2">
                            <li className="no-customer-error">
                                <img src="images/no-item.png" alt="no-item" />
                                <p className="pt-2">{noItemFound}</p>
                            </li>
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
}

export default CustomerSearch;