import React, {useState } from "react";
import "../Pos/pos.css";
import ProductForm from "./ProductForm/productForm";

function AddProduct(props) {

    const [showPopup, setShowPopup] = useState(false);
  
    function togglePopup() {
        setShowPopup(!showPopup);
    }
  
    return (
        <div className="add-customer-wrapper">
            <a href="#" onClick={togglePopup} className="info-button d-flex align-items-center justify-content-between">
                <span className="set-customer">Add Custom Product</span>
                <img className="Cart-icon" src="images/plus.png" alt="img" width="20" />
            </a>
            {showPopup ?
                <ProductForm
                    text='Add Custom Product'
                    closePopup={togglePopup}
                    openPopup={showPopup}
                />
                : null
            }
        </div>
    );
    
}

export default AddProduct;