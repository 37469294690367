import React, { useState } from "react";
import "./AddInventory.css";
import ItemDetails from "./itemDetails";
function Orders(props) {
	const [searchKey, setSearchKey] = useState("");
	
	const closePopup = (e) => {
		props.onChange(true);
	}

	const updateForm = (key) => {
		document.getElementById('search_key').value = key;
		setSearchKey(key);
	}

	async function handleSubmitByEnter(e) {
		if (e.key === 'Enter') {
			if(searchKey !== document.getElementById('search_key').value)
				setSearchKey(document.getElementById('search_key').value);
		}
	  }

	return (
		<div className="add-inventory">
		<div className="overlay" onClick={closePopup}></div>
		<div className="orders-wrapper">
			<div className="order-modal-header d-flex justify-content-between align-items-center">
				<h1 className="mb-0">Add Inventory</h1>
				<span onClick={closePopup}>X</span>
			</div>
				<div className="mt-4 px-3 refund-inner-wrapper">
						<div className="enter_order_wrapper mr-3 d-inline-block">
							<input id="search_key" className="form-control refund-input" placeholder="Enter SKU or EAN" onKeyDown={handleSubmitByEnter}/>
						</div>
						<div className="get_detail_btn d-inline-block">
							<button className="refund-btn back-button" type="button" onClick={
								e => { 
									if(searchKey !== document.getElementById('search_key').value)
										setSearchKey(document.getElementById('search_key').value);
								}
							}>Get Product Details</button>
						</div>
						{searchKey && (
                                <div className="view-order-wrapper">
                                    <ItemDetails searchKey={searchKey} onChange={(key) => updateForm(key) }/>
                                </div>
                            )}
				</div>
			
			<div className="order-modal-footer d-flex justify-content-end align-items-center">
				<button className="close-button" onClick={closePopup}>close</button>
			</div>
		</div>
		</div>
	);
}
export default Orders;
