import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-boost';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.slim.min';
import 'popper.js/dist/umd/popper';
import { StoreProvider } from './Store';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

const url = process.env.REACT_APP_URL_WITH_PORT+"/graphql";

const client = new ApolloClient({
  uri: url,
});

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
  transition: transitions.FADE,
  offset: '30px',
  containerStyle: {
    zIndex: 99999
  }
};

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <StoreProvider>
          <AlertProvider template={AlertTemplate} {...options}>
            <App />
          </AlertProvider>
        </StoreProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
