import React, {useEffect, useState} from "react";
import "./loader.css";

function Loader() {
	const [active, setActive] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setActive(false);
		}, 3000);	
	  }, []);
	

  return (
  		<div className={active ? "loader-overlay active-loader" : "loader-overlay"}>
	      <div className="intro-banner-vdo-play-btn pinkBg">
				<span className="ripple pinkBg"></span>
				<span className="ripple pinkBg"></span>
				<span className="ripple pinkBg"></span>
	      </div>
      	</div>
  );
}
export default Loader;
