import React, { useState } from "react";
import Navigation from "../Navigation/Navigation";
import "../Pos/pos.css";


const Search = (props) =>{
    const [searchValue, setSearchValue] = useState(false);
    const [isBarcodeSearch, setIsBarcodeSearch] = useState("");
    const [activeToggle, setActiveToggle] = useState('true');  

    const seachProduct =(e) => {
        if(e.target.value){
            setSearchValue(true);
        }else{
            setSearchValue(false);
           
        }
        props.onChange(e.target.value, isBarcodeSearch);
    }
    
    function barcodeSearch(e) {
        if (e.target.checked) {
            setIsBarcodeSearch('1');
        } else {
            setIsBarcodeSearch('0');
        }
    }
    function toggleSidebar(){
        setActiveToggle(!activeToggle);
    }

    async function handleSubmitByEnter(e) {
		if (e.key === 'Enter') {
            seachProduct(e);
            
		}
      }
      
    function handleSubmit(e){
       return false;
    }

    return (
        <div>
            <div className={activeToggle?'sidebar' : 'sidebar show-sidebar' }>
                <div className="overlay" onClick={toggleSidebar}></div>
                <Navigation onChange={toggleSidebar} />
            </div>
    		<nav className="navbar navbar-expand-lg navbar-light px-0 py-3">
                <div className="container-fluid px-0">
                    <div className="row header-items-wrapper ml-0">
                        <div className="col-lg-1 col-md-2 text-left">
                            <button onClick={toggleSidebar} type="button" id="sidebarCollapse" className="btn bg-white hamburger-icon">
                                <span className="mt-0"></span>
                                <span></span>
                                <span className="mb-0"></span>
                            </button>
                        </div>
                        <div className="col-lg-8 col-md-6 px-0">
                            <div className="search-form-wrapper">
                                <input className="search-bar-custom w-100 px-3" 
                                        id="search-box"
                                        type="search" 
                                        placeholder="Search here..." 
                                        aria-label="Search" 
                                        onKeyUp={seachProduct}
                                        onKeyDown={handleSubmitByEnter}
                                />
                                {!searchValue &&(
                                    <img className="search-img" src="images/item-search.png" alt="search-icon" width="15" height="15" />
                                )}
                                {searchValue &&(
                                <img className="search-crosss-img" src="images/cancel.png" alt="cross" width="15" height="15" 
                                        onClick={(e) => { 
                                            document.getElementById('search-box').value="";
                                            setSearchValue(false);
                                            props.onChange(document.getElementById('search-box').value="", isBarcodeSearch);
                                            
                                }}/>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 d-flex align-items-center justify-content-end">
                            <div className="barcode-checkbox">
                                <input id="barcheck" type="checkbox" 
                                       aria-label="Checkbox for following text input" 
                                       onChange={barcodeSearch}
                                />
                                <label htmlFor="barcheck" className="bar-code-text">Is Barcode Search</label>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Search;