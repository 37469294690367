import React, { useState } from 'react';
import './customerForm.css';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Loader from '../../Loader/Loader';
import { Store } from '../../../Store';

function CustomerForm(props) {
    const CREATE_CUSTOMER_MUTATION = gql`
    mutation createCustomer($firstname: String!, $lastname: String!, $contact_number: String!,$email: String!, $password: String!) {
        createCustomer(
            input: {
                firstname: $firstname
                lastname: $lastname
                contact_number: $contact_number
                email: $email
                password: $password
            }
        ) {
            customer {
                id,
                firstname
            }
        }
    }
    `;
    
    const { dispatch } = React.useContext(Store);
    const [createCustomer, { loading, error, data }] = useMutation(CREATE_CUSTOMER_MUTATION);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [contact_number, setContact_number] = useState("");
    const [email, setEmail] = useState("");
    const [requiredFirstNameErrorMessage, setRequiredFirstNameErrorMessage] = useState("");
    const [requiredLastNameErrorMessage, setRequiredLastNameErrorMessage] = useState("");
    const [requiredContactErrorMessage, setRequiredContactErrorMessage] = useState("");
    const [validEmailMessage, setValidEmailMessage] = useState("");

    function closePopup() {
        props.closePopup();
    }

    function validateEmail() {
        let regularExpForEmail = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
        if (!regularExpForEmail.test(email)) {
            return true;
        }
        return false;
    }

    function addCustomer() {
        let emailErrorMessage = "";
        if (email && validateEmail()) {
            emailErrorMessage = "Please enter a valid email address (Ex: johndoe@domain.com)."
        }
        setRequiredFirstNameErrorMessage(firstname ? '' : "This is a required field.");
        setRequiredLastNameErrorMessage(lastname ? '' : "This is a required field.");
        setRequiredContactErrorMessage(contact_number ? '' : "This is a required field.");
        setValidEmailMessage(emailErrorMessage);
        formSubmit();
    }

    async function formSubmit() {
        if (firstname
            && lastname
            && contact_number) {
            let password = contact_number + "pos@1234";
            let customerEmail = contact_number + "@" + process.env.REACT_APP_POS_DOMAIN_NAME;
            if (email && validateEmail) {
                customerEmail = email;
            }
            await createCustomer({
                variables: {
                    firstname: firstname,
                    lastname: lastname,
                    contact_number: contact_number,
                    email: customerEmail,
                    password: password
                }
            });
            document.getElementById("add_new_customer_form").reset();
            dispatch({
                type: 'customer_update',
                payload: true
              });
        }
    }

    function setFirstName(e) {
        setFirstname(e.target.value);
        setRequiredFirstNameErrorMessage(e.target.value ? "" : "This is a required field.");
    }

    function setLastName(e) {
        setLastname(e.target.value);
        setRequiredLastNameErrorMessage(e.target.value ? "" : "This is a required field.");
    }

    function setContactNumber(e) {
        setContact_number(e.target.value);
        setRequiredContactErrorMessage(e.target.value ? "" : "This is a required field.");
    }

    function setCustomerEmail(e) {
        setEmail(e.target.value);
    }

    return (
        <div className='pos-customer-form-popup'>
            <div className="pos-form-inner-wrap">
                {data && data.createCustomer.customer.id && (
                    <div className="success-message">
                        <img className="mr-2" src="images/CORRECT.png" alt="correct-img" width="20" height="20" />
                        {data.createCustomer.customer.firstname + " added successfully."}
                    </div>
                )}
                {loading && (
                    <div className="loading">
                        <Loader />
                    </div>
                )}
                {error && error.graphQLErrors.map(({ message }, i) => (
                    <div className="error-message">
                        <img className="mr-2" src="images/warning.png" alt="error-img" width="20" height="20" />
                        <span key={i}>{message}</span>
                    </div>
                ))}
                <div className={"pos-customer-form-inner " + (props.openPopup ? "open-modal" : "")}>
                    <div className="modal-header pos-modal-header">
                        <h2 className="modal-title">{props.text}</h2>
                    </div>
                    <div className="modal-body">
                        <form id="add_new_customer_form" className="form-body d-flex justify-content-between flex-wrap">
                            <div className="pos-customer-field mb-3">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" for="firstname">First Name
                                    <span className="required">*</span>
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="firstname"
                                            placeholder="First Name"
                                            type="text"
                                            onChange={setFirstName.bind(this)}
                                        />
                                    </div>
                                </div>
                                {requiredFirstNameErrorMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{requiredFirstNameErrorMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field mb-3">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" for="firstname">Last Name
                                    <span className="required">*</span>
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="firstname"
                                            placeholder="Last Name"
                                            type="text"
                                            onChange={setLastName.bind(this)}
                                        />
                                    </div>
                                </div>
                                {requiredLastNameErrorMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{requiredLastNameErrorMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" for="contactNumber">Contact Number
                                    <span className="required">*</span>
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="contactNumber"
                                            placeholder="Contact Number"
                                            type="text"
                                            onChange={setContactNumber.bind(this)}
                                        />
                                    </div>
                                </div>
                                {requiredContactErrorMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{requiredContactErrorMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" for="email">Email
                                </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="email" placeholder="Email"
                                            type="text"
                                            onChange={setCustomerEmail.bind(this)}
                                        />
                                    </div>
                                </div>
                                {validEmailMessage && (
                                    <div className="error-message top-error field-error">{validEmailMessage}</div>
                                )}
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer pos-modal-footer pt-0">
                        <button className="add-customer-button" onClick={addCustomer.bind(this)}>Add Customer</button>
                        <button className="close-button" onClick={closePopup.bind(this)}>Close</button>
                    </div>
                </div>
            </div>



        </div>
    );
}

export default CustomerForm;