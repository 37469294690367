import React, {useState } from "react";
import "../Pos/pos.css";
import CustomerForm from "./CustomerForm/CustomerForm";

function AddCustomer(props) {

    const [showPopup, setShowPopup] = useState(false);
  
    function togglePopup() {
        setShowPopup(!showPopup);
    }
  
    return (
        <div className="add-customer-wrapper">
            <a  href="#"
                className="info-button d-flex align-items-center justify-content-between"
                onClick={togglePopup}
                >										
                <span className="set-customer">Add Customer</span>
                <img className="Cart-icon" src="images/plus.png" alt="img"/>
            </a>
            {showPopup ?
                <CustomerForm
                    text='Add New Customer'
                    closePopup={togglePopup}
                    openPopup={showPopup}
                />
                : null
            }
        </div>
    );
    
}

export default AddCustomer;