import React, { useState } from "react";
import "./refund.css";
import CreateRefund from "./createRefund";
import Loader from "../Loader/Loader";
import $ from 'jquery';
import { useRefund } from '../../talons/useCustom'
import { useAlert } from "react-alert";
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import SmartDataTable from '../SmartTable/react-smart-data-table/lib/SmartDataTable';
import OrderViewSkeleton from "./OrderViewSkeleton.js";
import { Store } from '../../Store';

const CREDITMEMO_LIST = gql`
			query agentCreditMemo($agent_id: Int) {
				agentCreditMemo(agent_id: $agent_id) {
					allCreditMemoRecords{
                         id
                         increment_id
                         grand_total
                         created_at
                      }
                }
            }
        `;
        
function Refund(props) {
	
    const [orderViewId, setOrderViewId] = useState("");
    const {dispatch } = React.useContext(Store);
    const [showLoader, setShowLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);


    const alert = useAlert();
    const { refundOrder, resdata,reserror } = useRefund();
    if(typeof reserror !="undefined"){
        alert.error(reserror.graphQLErrors[0].message);
    }

    if(typeof resdata !="undefined"){
        if(resdata.success){
            if(!showAlert){
                setShowAlert(true);
                alert.success('Credit Memo Created');
            }
        }else{
            alert.error('Something went wrong while creating credit memo');
        }
       
    }
    const agentDetails = JSON.parse(localStorage.getItem('agentDetails'));

    const datavariables = { agent_id: agentDetails.id };
    const { loading, error, data,refetch } = useQuery(CREDITMEMO_LIST, {
        variables: datavariables,
        fetchPolicy: 'network-only' 
    });
    
    
    var tableData = [];
	if (typeof data != 'undefined') {
		data.agentCreditMemo.allCreditMemoRecords.forEach(v => {
			tableData.push({
                created_at: v.created_at,
				id: v.id,
				increment_id: v.increment_id,
				grand_total: v.grand_total
			});
		});
	}
    
    
    const handleRefund = async () =>{
        setShowLoader(true);
        var itemData ="";
        var data = $('#refund_form').serializeArray().reduce((f,c) => {f[c['name']]=(f[c['name']])?[].concat(f[c['name']],c['value']):c['value']; return f}, {} );
        Object.keys(data).forEach(key=>{
            if(key.indexOf("item_id_") != -1){
               var itemid =  key.replace("item_id_", "");
               itemData = itemData+","+itemid+"->"+data["item_id_"+itemid];
            }
        });
        let agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
        await refundOrder({agent_id: agentDetails.id,order_id:data.order_id,itemData:itemData,adjustment_refund:parseInt(data.adjustment_refund),adjustment_fee:parseInt(data.adjustment_fee)});
        document.getElementById('order_view_id').value = "";
        setOrderViewId("");
        refetch();
        dispatch({
            type: 'product_update',
            payload: true
        });
        setShowLoader(false);
    }
    async function handleSubmitByEnter(e) {
		if (e.key === 'Enter') {
            if(orderViewId != document.getElementById('order_view_id').value)
                setOrderViewId(document.getElementById('order_view_id').value);
		}
	}
    
  
	return (
            <div className="show-refund">
                    <div className="overlay" onClick={props.close}></div>
                    <div className="refund-wrapper">
                        <div className="refund-header d-flex justify-content-between">
                            <h5>Create Refund</h5>
                            <span className="close-icon" onClick={props.close}>X</span>
                        </div>
                        <div className="mt-4 refund-inner-wrapper">
                                <div className="enter_order_wrapper mr-3 d-inline-block">
                                    <input id="order_view_id" className="form-control refund-input" placeholder="Enter Order Id" onKeyDown={handleSubmitByEnter} />
                                </div>
                                <div className="get_detail_btn d-inline-block">
                                    <button className="refund-btn back-button" type="button" onClick={
                                        e => {
                                            if(orderViewId != document.getElementById('order_view_id').value)
                                                setOrderViewId(document.getElementById('order_view_id').value);
                                        }
                                    }>Get Order Details</button>
                                </div>
                            {orderViewId ? (
                                <div className="view-order-wrapper">
                                    <CreateRefund orderId={orderViewId} showLoader={showLoader}/>
                                </div>
                            ):tableData.length > 0 && (
                                <div className="order-modal-body">
                                    {orderViewId == "" && (
                                        <SmartDataTable
                                            data={tableData}
                                            name="po-table"
                                            className="ui compact selectable table"
                                            sortable
                                            perPage={10}
                                        />
                                    )}
                                </div>
                            )}
                            {data && tableData.length === 0 && (
                                <div className="order-modal-body">
                                    <div>
                                        <p>{"No Orders Found"}</p>
                                    </div>
                                </div>
                            )}
                            {loading && (
                                <div>
                                    <OrderViewSkeleton />
                                </div>
                            )}
                          
                            
                        </div>
                        {orderViewId && (
                            <div className="refund-footer text-right">
                               
                                <button className="close-button" type="button" onClick={props.close}>Close</button>
                                <button className="secondary-btn" type="button" onClick={() => handleRefund()}>Refund order</button>
                            </div>
                        )}
                        
                        
                    </div>
            </div>


	);
}
export default Refund;
