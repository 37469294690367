import React, { useState } from "react";
import "./cart.css";
import CustomerSearch from "../CustomerSearch";
import AddCustomer from "../AddCustomer";
import AddProduct from "../AddProduct";
import Refund from "../Refund/refund";
import { Store } from '../../Store';
import { useProduct, useCheckout } from '../../talons/useCustom';
import { Scrollbars } from 'react-custom-scrollbars';
import { useAlert } from "react-alert";
import  { Redirect } from 'react-router-dom';
import $ from 'jquery';
import CartSkeleton from './cartSkeleton.js';

function Products(props) {
	const { state } = React.useContext(Store);
	const messageAlert = useAlert();
	const [activeToggle, setActiveToggle] = useState('true');
	const [tenderedAmt, setTenderedAmt] = useState("");
	const [changeAmt, setChangeAmt] = useState("");
	const [paymentMode, setPaymentMode] = useState("cash");
	const [transactionId, setTransactionId] = useState("");
	const [serviceName, setServiceName] = useState("");
	const [couponCodeToggle, setCouponCodeToggle] = useState(false);
	const [showPaymentOption, setShowPaymentOption] = useState(false);
	const [showReundForm, setShowReundForm] = useState(false);
	const [couponCode, setCouponCode] = useState(false);

	
	const {
		removeProductFromCart,
		updateQuoteItemQtyInCart,
		applyCoupon,
		removeCoupon
	} = useProduct();
	const { checkout } = useCheckout();
	
	const quoteId = localStorage.getItem('quote_id');
	const customerToken = localStorage.getItem('customerToken');
	const currencySymbol = localStorage.getItem('currency_symbol');
	let cartDetails = "";
	if (quoteId && customerToken) {
		cartDetails = JSON.parse(localStorage.getItem('cartDetails'));
	}
	let agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
	if(agentDetails != null){
		agentDetails['pos_settings'] = JSON.parse(agentDetails.pos_settings);
	}else{
		return <Redirect to='/' />
	}
	
	
	var cucode = false;
	if(cartDetails && cartDetails.coupon_code){
		cucode = cartDetails.coupon_code;
		if(couponCode !== cucode)
			setCouponCode(cucode);
	}
	
	const handleLogOut = () => {
		localStorage.clear();
		props.history.push("/");
	}

	const removeItemfromcart = (item, e) => {
		removeProductFromCart({ 'item_id': item.item_id });
	}

	const handleClick = (type, item) => {
		var qty;
		if (item.item_id) {
			let quantity = item.qty;
			if (type === 'decrease') {
				if (quantity - 1 < 1) {
					qty = 1;
				} else {
					qty = quantity - 1;
				}
				updateQuoteItemQtyInCart({ "item_id": item.item_id, "sku": item.sku, "qty": qty });
			} else {
				let quantity = item.qty;
				if (quantity + 1 > 100) {
					qty = 100;
				} else {
					qty = quantity + 1;
				}
				updateQuoteItemQtyInCart({ "item_id": item.item_id, "sku": item.sku, "qty": qty });
			}
		}
	}

	const applyCouponCode = () => {
		if (couponCode) {
			applyCoupon({ 'coupon_code': couponCode });
		}
	}

	const removeCouponFromCart = () => {
		removeCoupon();
	}

	const placeOrder = () => {
		if (cartDetails) {
			if (!(cartDetails.items && cartDetails.items.length > 0)) {
				messageAlert.show("There is no item(s) in cart.");
			}
			if (!(agentDetails.cashondelivery || (agentDetails.paybycard && agentDetails.paybycard_services))) {
				messageAlert.show("There is no payment option available, kindly discuss with owner.");
			}
			if (cartDetails.items && cartDetails.items.length > 0 && (agentDetails.cashondelivery || (agentDetails.paybycard && agentDetails.paybycard_services))) {
				setShowPaymentOption(true);
			}
			if(!agentDetails.cashondelivery){
				setPaymentMode("card");
			}
		} else {
			messageAlert.show("There is no item(s) in cart.");
		}
	}

	const confirmCheckout = async () => {
		if (paymentMode === "cash") {
			if (tenderedAmt && tenderedAmt >= cartDetails.base_grand_total) {
				localStorage.setItem("paymentInformation", JSON.stringify(
					{
						"payment_mode" : agentDetails.cashondelivery_title,
						"cart_total" : cartDetails.base_grand_total,
						"tendered_amount" : tenderedAmt,
						"change_amount" : changeAmt
					}
				));
				localStorage.setItem('payment_mode', 'cash');
				await checkout();
				$('.pos-payment-option-container').find('input:text').val('');  
			} else {
				messageAlert.show("Tendered Amount is less than Cart Total.");
			}
		} else if (paymentMode === "card") {
			if (!serviceName && !transactionId) {
				messageAlert.show("Please select Service Name and enter Transaction Id.");
			} else if (!serviceName) {
				messageAlert.show("Please select Service Name.");
			} else if (!transactionId) {
				messageAlert.show("Please enter Transaction Id.");
			}
			if (serviceName && transactionId) {
				localStorage.setItem("paymentInformation", JSON.stringify(
					{
						"payment_mode" : agentDetails.paybycard_title,
						"cart_total" : cartDetails.base_grand_total,
						"service_name" : serviceName,
						"transaction_id" : transactionId
					}
				));
				localStorage.setItem('payment_mode', 'card');
				await checkout();
				$('.pos-payment-option-container').find('input:text').val('');  
			}
		}
	}

	const setTenderedAmount = (e) => {
		let tenderedAmount = e.target.value;
		let base_grand_total = cartDetails.base_grand_total;
		let numberRE = /^[0-9\b]+$/;
		if (e.target.value && !numberRE.test(e.target.value)) {
			messageAlert.error("Please enter only number.");
		} else {
			setTenderedAmt(tenderedAmount);
			if (tenderedAmount >= base_grand_total) {
				setChangeAmt((tenderedAmount - base_grand_total));
			}
		}
	}

	const closePaymentOptionPopup = () => {
		setShowPaymentOption(false);
	}

	
	
	if(couponCode === false){
		localStorage.removeItem('coupon_response');
	}
	let coupon_response = localStorage.getItem('coupon_response');	
	
	if (coupon_response) {
		if (cartDetails && cartDetails.coupon_code) {
			coupon_response = "";
		}
		if(!couponCode)
			localStorage.removeItem('coupon_response');
	}

	let coupon_removed_response = localStorage.getItem('coupon_removed_response');

	if (coupon_removed_response) {
		setCouponCodeToggle(false)
		localStorage.removeItem('coupon_removed_response');
	}

	function openSubMenu() {
		setActiveToggle(!activeToggle);
	}

	if(localStorage.getItem('placedOrderId')){
		localStorage.setItem('orderIdForInvoice',localStorage.getItem('placedOrderId'));
		localStorage.removeItem('placedOrderId')
		window.open("/inoviceSlip", "_blank");
	}

	let agentName = localStorage.getItem('agentName');
	if(state.checkOutSuccess && showPaymentOption){
		setTenderedAmt('');
		setChangeAmt('');
		setShowPaymentOption(false);
  	}
	return (
		<div className="cart-wrapper-right">
			<div className="unsticky-wrapper">
				<div className="right-cart-section">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-4 col-md-4">
								<a className="user-profile" href="#" onClick={openSubMenu}>
									<span><img className="mr-1" alt="images" src="images/user(1).png" width="20" height="20" /></span>
									<span className="username">{agentName}</span>
									<img className="dropdown-icon" src="images/caret-down.png" alt="img" />
									
								</a>
							</div>
							<div className="col-lg-5 col-md-5">
								<button className="refund-btn" onClick={e => {
									if(showReundForm !== true)
										setShowReundForm(true)
								}}>Create Refund</button>
								{showReundForm && (
									<div><Refund close={
										e => {
											if(showReundForm !== false)
												setShowReundForm(false)
										}
									}/></div>
								)}
								
							</div>
							<div className="col-lg-3 col-md-3 text-right">
								<span className="position-relative">
									<img className="Cart-icon mr-1" src="images/shopping-cart.png" alt="cart-img" width="20" height="20" />
									{cartDetails && cartDetails.items_qty > 0 && (
										<span className="cart-value">{cartDetails.items_qty}</span>
									)}
								</span>
								<span className="cart-text">Cart</span>
							</div>
						</div>
					</div>
					<div className={activeToggle ? 'user-submenu' : 'user-submenu opensubmenu'}>
						<a className="logout-btn p-1" onClick={handleLogOut} href="/#">
							<span><img width="20" height="20" alt="images" src="images/logout.png" className="d-inline-block mr-1 logout-icon" /></span>
							<span className="d-inline-block">Log out</span>
						</a>
					</div>
				</div>
				<div className="search-customer">
					<CustomerSearch
						placeholder='Search Customer'
						noItemFound='No customer found'
					/>
				</div>
				<div className="add-more">
					<div className="row">
						<div className="col-lg-6 col-md-12 add-customer-sction">
							<AddCustomer />
						</div>
						<div className="col-lg-6 col-md-12 add-custome-product">
							<AddProduct />
						</div>
					</div>
				</div>
				<div className="tab-carts text-left">
					<p className="my-cart-title">Shopping Cart</p>
				</div>
				<div className="product-table-wrapper">
					
					
					{cartDetails && (typeof cartDetails.items != 'undefined') && (
							<div className="product-table-inner content" id="content-2">
								{cartDetails && (cartDetails.items.length > 0) && (
									<Scrollbars className="custom-cart-scroll" autoHeight>
										<ul className="product-table pl-0 mb-0">
											{cartDetails.items && cartDetails.items.map((item, index) => {
												return (
													// <div>
													// 	<CartSkeleton />
													// </div>
													<li className="cart-items-list d-flex align-items-center" key={index}>
														<div className="product-cart-info text-left">
															<p className="product-cart-name mb-0">{item.name}</p>
														</div>
														<div className="product-price-wrapper">
															<p className="product-price mb-0">
																<span>{currencySymbol + item.price}</span>
															</p>
														</div>
														<div className="quanity d-inline-flex">
														    <span className="qty-action remove-qty" onClick={() => handleClick('decrease', item)}>-</span>
															<span className="quantity-input ng-pristine ng-valid ng-touched">{item.qty}</span>
															<span className="qty-action add-qty" onClick={() => handleClick('increase', item)}>+</span>
														</div>
														<div className="product-price row-total">
															<p className="price mb-0">{currencySymbol + (item.row_total)}</p>
														</div>
														<div className="remove">
															<p onClick={(e) => removeItemfromcart(item, e)} className="mb-0">
																<img className="remove-icon" src="images/delete.png" alt="remove button" />
															</p>
														</div>
													</li>
												);
											})}
										</ul>
									</Scrollbars>
								)}
							</div>
						)}
				
				</div>
			</div>
			<div className="billing-wrapper">
				{showPaymentOption && cartDetails && (
					<div className='pos-customer-form-popup'>
						<div className="pos-form-inner-wrap">
							<div className={"pos-customer-form-inner " + (showPaymentOption ? "open-modal" : "")}>
								<div className="modal-header pos-modal-header">
									<h2 className="modal-title">{"Checkout"}</h2>
								</div>
								<div className="modal-body">
									<div className="pos-payment-option-container text-left">
										<div className="pos-row mb-4">
											<label className="title-payment-mode mb-0">{"Select Payment Mode : "}</label>
											<div className="field-wrapper payment-mehtod-type d-inline-block">
												{agentDetails.cashondelivery && (
													<button className={"payment-method-button mr-2 "+((paymentMode === "cash") ? "selected" : "")} 
															name="pay_by_cash" 
															onClick={() => {setPaymentMode("cash")}}>
														{agentDetails.cashondelivery_title}
													</button>
												)}
												{agentDetails.paybycard && (
													<button className={"payment-method-button "+((paymentMode === "card") ? "selected" : "")} name="pay_by_card" onClick={() => {setPaymentMode("card")}}>
														{agentDetails.paybycard_title}
													</button>
												)}
											</div>
										</div>
										<div className="pos-row d-flex align-items-center">
											<label className="mb-0">{"Cart Total"}</label>
											<div className="field-wrapper amount-wrapper">
												<div className="field-with-currency">
													<span className="currency-symbol">{currencySymbol}</span>
													<input name="cart_total" disabled="disabled" value={cartDetails.base_grand_total}/>
												</div>
											</div>
										</div>
										{(paymentMode === "cash") && (
											<div className="pos-row d-flex align-items-center">
												<label className="mb-0">{"Tendered Amount"}</label>
												<div className="field-wrapper amount-wrapper">
													<div className="field-with-currency">
														<span className="currency-symbol">{currencySymbol}</span>
															<input name="tendered_amount" onChange={(e) => setTenderedAmount(e)} value={tenderedAmt}/>
													</div>
												</div>
											</div>
										)}
										{(paymentMode === "card") && (
											<div className="pos-row d-flex align-items-center">
												<label className="mb-0">{"Service Name"}</label>
												<div className="field-wrapper amount-wrapper service-wrap">
													<div className="field-with-currency select-field">
														<select className="service-name-type" name="service_name" onChange={(e) => {setServiceName(e.target.value)}}>
															<option value="">{"-- Select  --"}</option>
															{agentDetails.paybycard_services && JSON.parse(agentDetails.paybycard_services).map((service, index) => {
																return (
																	<option value={service}>
																		{service}
																	</option>
																);
															})}
														</select>
													</div>
												</div>
											</div>
										)}
										{(paymentMode === "cash") && (
											<div className="pos-row d-flex align-items-center">
												<label className="mb-0">{"Change Amount"}</label>
												<div className="field-wrapper amount-wrapper">
													<div className="field-with-currency">
														<span className="currency-symbol">{currencySymbol}</span>
														<input name="change_amount" disabled="disabled" value={changeAmt}/>
													</div>
												</div>
											</div>
										)}
										{(paymentMode === "card") && (
											<div className="pos-row d-flex align-items-center">
												<label className="mb-0">{"Transaction Id"}</label>
												<div className="field-wrapper amount-wrapper">
													<div className="field-with-currency">
														<input className="transaction-id-input" name="transaction_id" onChange={(e) => {setTransactionId(e.target.value)}}/>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="modal-footer pos-modal-footer pt-0">
									<button className="add-customer-button" onClick={confirmCheckout}>Confirm Checkout</button>
									<button className="close-button" onClick={closePaymentOptionPopup}>Cancel</button>
								</div>
							</div>
						</div>
					</div>
				)}
				<ul className="list-group bottom-sticky-cart">
					<li className="list-items list-group-item subtotal d-flex justify-content-between align-items-center">
						<span className="manage-font">{agentDetails.pos_settings.subtotal_label}</span>
						{cartDetails && (cartDetails.subtotal > 0) && (
							<span className="cart-subtotal">{currencySymbol + cartDetails.subtotal}</span>
						)}
					</li>
					<li className="list-items list-group-item tax d-flex justify-content-between align-items-center">
						<span className="tax-input">{agentDetails.pos_settings.tax_label}</span>
						{cartDetails && (cartDetails.tax_amount > 0) && (
							<span className="cart-tax-amount">{currencySymbol + cartDetails.tax_amount}</span>
						)}
					</li>
					<li className="list-items list-group-item cupon-code p-0">
						{cartDetails && cartDetails.coupon_code && (
							<div className="entered-coupon">
								<span className="correct-img-wrap">
									<img src="images/correct-icon.png" alt="correct-coupon" width="20" height="20" />
								</span>
								<input name="cart_coupon" type="text" disabled="disabled" value={cartDetails.coupon_code} />
								<button className="cancel-button" onClick={removeCouponFromCart}>Cancel</button>
							</div>
						)}
						{cartDetails && !cartDetails.coupon_code && (
							<div className="apply-coupon-wrap">
								<div className="d-flex justify-content-between apply-coupon-text">
									<span className="manage-font">Apply Coupon Code</span>
									<span className="add-coupon-img" onClick={() => setCouponCodeToggle(!couponCodeToggle)}>
										<img className="plus-icon" src="images/plus.png" alt="img" width="20" height="20"></img>
									</span>
								</div>
								<div className={couponCodeToggle ? "hide-input show-input" : "hide-input"}>
									<span className="back-img">
										<img className="img-fluid" alt="images" src="images/back.png" width="20" height="20" onClick={() => setCouponCodeToggle(!couponCodeToggle)} />
									</span>
									<input name="cart_coupon" placeholder="Enter coupon code" type="text" onChange={e => setCouponCode(e.target.value)} />
									<button className="apply-coupon-btn" onClick={applyCouponCode}>Apply</button>
								</div>

							</div>
						)}
						{coupon_response && coupon_response !== "true" && (
							<span className="coupon-response">{coupon_response}</span>
						)}

					</li>
					
					
					<li className="list-items list-group-item discount d-flex justify-content-between align-items-center">
						<p className="mb-0 w-100 d-flex justify-content-between">
							<span className="manage-font">{agentDetails.pos_settings.discount_label}</span>
							{cartDetails && (Math.abs(cartDetails.discount_amount) > 0) && (
								<span className="cart-tax-amount">-{currencySymbol + Math.abs(cartDetails.discount_amount)}</span>
							)}
						</p>
					</li>
					<li className="py-0 pr-0 pl-2 list-group-item checkout blue-bg d-flex justify-content-between align-items-center">
						<span className="checkout-amt">
							<span className="total-text">{agentDetails.pos_settings.grand_total_label}:</span>
							{cartDetails && (cartDetails.base_grand_total > 0) && (
								<span className="cart-grand-total">{currencySymbol + cartDetails.base_grand_total}</span>
							)}
						</span>
						<button className="checkout-btn position-relative" onClick={placeOrder}>
							<span className="checkout-btn-text">Checkout</span>
							{/* <div className="ripple"></div> */}
						</button>
					</li>
				</ul>
			</div>
		</div>
	);
}
export default Products;
