import React, { useState } from "react";
import SmartDataTable from '../SmartTable/react-smart-data-table/lib/SmartDataTable';
import "./Orders.css";
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import OrderView from "../OrderView/OrderView";
import OrderSkeleton from "./orderSkeleton.js";

const ORDERS_QUERY = gql`
			query getAgentOrders($agent_id: String) {
				agentOrders(agent_id: $agent_id) {
				  allOrderRecords {
					id
					increment_id
					customer_name
					grand_total
					created_at
					status
				  }
				}
			  }
		`;

function Orders(props) {
	let datavariables = { "agent_id": localStorage.getItem('agentId') };
	const [orderViewId, setOrderViewId] = useState("");
	const {loading, data } = useQuery(ORDERS_QUERY, {
		variables: datavariables,
		fetchPolicy: "network-only"
	});
	var tableData = [];
	if (typeof data != 'undefined' && data.agentOrders && data.agentOrders.allOrderRecords ) {
		data.agentOrders.allOrderRecords.forEach(v => {
			tableData.push({
				order_id: v.increment_id,
				order_date: v.created_at,
				customer: v.customer_name,
				total: v.grand_total,
				actions: v.id
			});
		});
	}
	const closePopup = (e) => {
		props.onChange(true);
	}

	const handleOrderView = (e) => {
		setOrderViewId(e.target.id);
	}

	function getHeaders()  {
		return {
			order_id:{
				sortable: true,
				filterable: true,
			},
			actions: {
				text: 'Actions',
				sortable: true,
				filterable: true,
				transform: (value, idx, row) =><button id={value} className="view-order secondary-btn" onClick={(e) => handleOrderView(e)}>View</button>
			}
		}
	}

	if (orderViewId) {
		return (
			<div className="orders-wrapper">
				<div className="overlay order-details-wrapper" onClick={closePopup}></div>
				<div className="order-modal-header d-flex justify-content-between align-items-center">
					<h1 className="mb-0">Orders</h1>
				</div>
				{orderViewId && (
					<OrderView orderId={orderViewId} />
				)}
				<div className="order-modal-footer d-flex justify-content-end align-items-center">
					<a className="print-button mr-3" target="_blank" href="/inoviceSlip" >print</a>
					<button className="back-button secondary-btn" onClick={
						e => {
								setOrderViewId("");
						}
					}>Back to orders</button>
				</div>
				{/* {showInvoice && (
					<InvoicePrint />
				)} */}
			</div>
		);
	} else {
		return (
			<div className="orders-wrapper">
			    <div className="overlay order-details-wrapper" onClick={closePopup}></div>
				<div className="order-modal-header d-flex justify-content-between align-items-center">
					<h1 className="mb-0">Orders</h1>
					<span onClick={closePopup}>X</span>
				</div>
				{tableData.length > 0 && (
					<div className="order-modal-body">
						{orderViewId === "" && (
							<SmartDataTable
								data={tableData}
								name="po-table"
								headers={getHeaders()}
								className="ui compact selectable table"
								sortable
								perPage={10}
							/>
						)}
					</div>
				)}
				{data && tableData.length === 0 && (
					<div className="order-modal-body">
						<div>
							<p>{"No Orders Found"}</p>
						</div>
					</div>
				)}
				{loading && (
					<div>
						<OrderSkeleton />
					</div>
				)}
				<div className="order-modal-footer d-flex justify-content-end align-items-center">
					<button className="close-button" onClick={closePopup}>close</button>
				</div>
			</div>
		);
	}
}
export default Orders;
