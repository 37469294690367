import React, { useState } from 'react';
import './productForm.css';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Loader from '../../Loader/Loader';
import { Store } from '../../../Store';

function ProductForm(props) {
    const { dispatch } = React.useContext(Store);
    const CREATE_PRODUCT_MUTATION = gql`
    mutation saveProduct($productName: String!, $sku: String!, $price: String!,$weight: String!, $ean: String!,$qty: String!,$agentId: Int!) {
        saveProduct(
                productName: $productName
                sku: $sku
                price: $price
                weight: $weight
                ean: $ean
                qty: $qty
                agentId: $agentId
        ) {
            success,
            product_id,
            product_name
        }
    }
    `;

    const [createProduct, { loading, error, data }] = useMutation(CREATE_PRODUCT_MUTATION);
    const [productName, setProductname] = useState("");
    const [price, setPrice] = useState("");
    const [sku, setSku] = useState("");
    const [weight, setWeight] = useState("");
    const [ean, setEan] = useState("");
    const [quantity, setQuantity] = useState("");
    const [requiredProductNameErrorMessage, setRequiredProductNameErrorMessage] = useState("");
    const [requiredSkuErrorMessage, setRequiredSkuErrorMessage] = useState("");
    const [validQuantityMessage, setValidQuantityMessage] = useState("");
    const [validPriceMessage, setValidPriceMessage] = useState("");
    const [validWeightMessage, setValidWeightMessage] = useState("");
    const [validEanMessage, setValidEanMessage] = useState("");
    const [productId, setProductId] = useState("");
    function closePopup() {
        props.closePopup();        
    }

    function validateNumber(num) {
        let regularExpForNumber = /^[0-9]+$/;
        if (!regularExpForNumber.test(num)) {
            return true;
        }
        return false;
    }

    function addProduct() {
        setRequiredProductNameErrorMessage(productName ? '' : "This is a required field.");
        setRequiredSkuErrorMessage(sku ? '' : "This is a required field.");
        setValidPriceMessage(price && validateNumber(price) ? "Please enter a number" : (price ? "": "This is a required field."));
        setValidWeightMessage(weight && validateNumber(weight) ?  "Please enter a number" : "");
        setValidQuantityMessage(quantity && validateNumber(quantity) ?  "Please enter a number" : "");
        //setValidEanMessage(ean ? '' : "This is a required field.");
        formSubmit();
        
    }

    async function formSubmit() {
        if (productName && sku && price) {
            
            await createProduct({
                variables: {
                    productName: productName,
                    sku: sku,
                    price: price,
                    weight: weight,
                    ean: ean,
                    qty:quantity,
                    agentId:localStorage.getItem('agentId')
                }
            });
            await dispatch({
                type: 'product_update',
                payload: true
            });
        }
    }

    function handleProductName(e) {
        setProductname(e.target.value);
        setRequiredProductNameErrorMessage(e.target.value ? "" : "This is a required field.");
    }

    function handleSku(e) {
        setSku(e.target.value);
        setRequiredSkuErrorMessage(e.target.value ? "" : "This is a required field.");
    }

    function handleQuantity(e) {
        setQuantity(e.target.value);
        let msg;
        if(e.target.value && validateNumber(e.target.value)){
            msg = "Please enter a number"
        }
        setValidQuantityMessage(e.target.value ? "" :msg);
    }

    function handlePrice(e) {
        setPrice(e.target.value);
        let msg;
        if(e.target.value && validateNumber(e.target.value)){
            msg = "Please enter a number"
        } else{
            msg = "This is a required field.";
        }
        setValidPriceMessage(e.target.value ? "" : msg);
    }

    function handleWeight(e) {
        setWeight(e.target.value);
        let msg;
        if(e.target.value && validateNumber(e.target.value)){
            msg = "Please enter a number"
        }
        setValidWeightMessage(e.target.value ? "" : msg);
    }

    function handleEan(e) {
        setEan(e.target.value);
     //   setValidEanMessage(e.target.value ? "" : "This is a required field.");
    }

    if(data && data.saveProduct.success && productId !== data.saveProduct.product_id ){
        document.getElementById('productForm').reset();
        setProductId(data.saveProduct.product_id);
    }

    return (
        <div className='pos-customer-form-popup'>
            <div className="pos-form-inner-wrap">
                {data && data.saveProduct.product_id && (
                    <div className="success-message">
                        <img className="mr-2" src="images/CORRECT.png" alt="correct-img" width="20" height="20" />
                        {data.saveProduct.product_name + " added successfully."}
                    </div>
                )}
                {loading && (
                    <div className="loading">
                        <Loader />
                    </div>
                )}
                {error && error.graphQLErrors.map(({ message }, i) => (
                    <div className="error-message">
                        <img className="mr-2" src="images/warning.png" alt="error-img" width="20" height="20" />
                        <span key={i}>{message}</span>
                    </div>
                ))}
                <div className={"pos-customer-form-inner " + (props.openPopup ? "open-modal" : "")}>
                    <div className="modal-header pos-modal-header">
                        <h2 className="modal-title">{props.text}</h2>
                    </div>
                    <div className="modal-body">
                        <form id="productForm" className="form-body d-flex justify-content-between flex-wrap">
                            <div className="pos-customer-field mb-2">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="productname">Product Name
                                    <span className="required">*</span>
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="productname"
                                            placeholder="Product Name"
                                            type="text"
                                            onChange={handleProductName.bind(this)}
                                        />
                                    </div>
                                </div>
                                {requiredProductNameErrorMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{requiredProductNameErrorMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field mb-2">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="sku">SKU
                                    <span className="required">*</span>
                                    </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="sku"
                                            placeholder="SKU"
                                            type="text"
                                            onChange={handleSku.bind(this)}
                                        />
                                    </div>
                                </div>
                                {requiredSkuErrorMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{requiredSkuErrorMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field mb-2">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="price">Price
                                    <span className="required">*</span>
                                </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="price" placeholder="Price"
                                            type="text"
                                            onChange={handlePrice.bind(this)}
                                        />
                                    </div>
                                </div>
                                {validPriceMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{validPriceMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field mb-2">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="weight">Weight
                                </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="weight" placeholder="Weight"
                                            type="text"
                                            onChange={handleWeight.bind(this)}
                                        />
                                    </div>
                                </div>
                                {validWeightMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{validWeightMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field mb-2">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="ean">EAN
                                </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="ean" placeholder="EAN"
                                            type="text"
                                            onChange={handleEan.bind(this)}
                                        />
                                    </div>
                                </div>
                                {validEanMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{validEanMessage}</div>
                                )}
                            </div>
                            <div className="pos-customer-field mb-2">
                                <div className="form-group mb-1 text-left">
                                    <label className="control-label" htmlFor="qty">Quantity
                                </label>
                                    <div className="form-field">
                                        <input
                                            className="form-control"
                                            id="qty" placeholder="Quantity"
                                            type="text"
                                            onChange={handleQuantity.bind(this)}
                                        />
                                    </div>
                                </div>
                                {validQuantityMessage && (
                                    <div className="error-message p-1 mb-2 field-error">{validQuantityMessage}</div>
                                )}
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer pos-modal-footer pt-0">
                        <button className="add-customer-button" onClick={addProduct.bind(this)}>Add Product</button>
                        <button className="close-button" onClick={closePopup.bind(this)}>Close</button>
                    </div>
                </div>
            </div>



        </div>
    );
}

export default ProductForm;