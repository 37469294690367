import React, { useState } from 'react';
import './App.css';
import Routes from './Routes';
import { Store } from './Store';


function App() {
  const { state, dispatch } = React.useContext(Store);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  
  return (
    <div className="App">
      <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
    </div>
  );
}   
export default App;
