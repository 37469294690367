import React from "react";
import ContentLoader from 'react-content-loader';

function inventorySkeleton() {
  return (
        <ContentLoader
            height={200}
            width={305}
            speed={.8}
            primaryColor="#E8E8E8"
            secondaryColor="#FFFFFF"
        >
            <rect height="15" x="50" width="95" y="5" />
            <rect height="15" x="150" width="100" y="5" />
            <rect height="15" x="50" width="95" y="25" />
            <rect height="15" x="150" width="100" y="25" />
            <rect height="15" x="50" width="95" y="45" />
            <rect height="15" x="150" width="100" y="45" />
            <rect height="15" x="50" width="95" y="65" />
            <rect height="15" x="150" width="100" y="65" />
            <rect height="15" x="50" width="95" y="85" />
            <rect height="15" x="150" width="100" y="85" />
            <rect height="15" x="120" width="50" y="105" />
            

        </ContentLoader>
  		);
}
export default inventorySkeleton;
