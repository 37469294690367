import React from "react";
import "./createRefund.css";
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import OrderViewSkeleton from "./OrderViewSkeleton";
import Loader from "../Loader/Loader";

const ORDERS_QUERY = gql`
			query getOrderDetails($id: Int) {
				salesOrder(id: $id) {
					increment_id
					customer_name
					grand_total
					created_at
					invoice_id
					subtotal
					telephone
					payment
					tax
					tax_for_refund
					status
					can_creditmemo
					creditmemo_total
					creditmemo_subtotal
					discount_amount
					discount_amount_for_refund
					pos
					items{
						id
						name
						price
						qty_ordered
						qty_refund
						row_total
						status
						row_total_for_refund
						sub_total
						tax_amount_for_refund
						discount_amount_for_refund
					}
				}
			  }
		`;

function CreateRefund(props) {													
	
	let datavariables = { id: props.orderId };
	const { loading, data } = useQuery(ORDERS_QUERY, {
		variables: datavariables,
		fetchPolicy: 'network-only' 
	});
	
	console.log(data);

	if (typeof data != 'undefined') {
		if(data.salesOrder.can_creditmemo === 0 || data.salesOrder.pos === 0){
            return (
				<div className="no-customer-error">
					<img src="images/no-item.png" alt="images" width="40" />
					<p className="no-prduct-refund">{"Order with id "+ props.orderId + " not eligible for credit memo"}</p>
				</div>
			);
		}else{
			localStorage.setItem('orderData',JSON.stringify(data.salesOrder));
			return (
				<form id="refund_form" method="post" >
					{props.showLoader && (
						<Loader />
					)}
					<div className="container-fluid p-0 pt-5">
						<div className="row p-0">
							<div className="col-6 text-right border-right my-1 order-info-label">
								<span className="order-info-label">Order ID : </span>
								<span>{data.salesOrder.increment_id}</span>
							</div>
							<div className="col-6 text-left">
								<span className="order-info-label">Order Date : </span>
								<span>{data.salesOrder.created_at}</span>
							</div>
						</div>
						<div className="row p-0">
							<div className="col-6 text-right border-right my-1 order-info-label">
								<span className="order-info-label">Customer Name :</span>
								<span>{data.salesOrder.customer_name}</span>
								 
							</div>
							<div className="col-6 text-left">
								<span className="order-info-label">Customer Phone :</span>
								<span>{data.salesOrder.telephone}</span>
							  </div>
						</div>
						<div className="row p-0">
							<div className="col-6 text-right border-right my-1 order-info-label">
								<span className="order-info-label">Payment Mode : </span>
								<span>{data.salesOrder.payment}</span>
							</div>
							<div className="col-6 text-left">
								<span className="order-info-label">Invoice Number : </span>
								<span>{data.salesOrder.invoice_id}</span>
							  </div>
						</div>
						<div className="row p-0">
							<div className="col-12">
								<h5 className="order-items-title text-left mt-4 mb-0 px-3">Order Items</h5>
							</div>
						</div>
						<div className="row p-0">
							<div className="col-sm-12 itm-table">
								<table className="custom-table table order-specific-iems">
									<thead >
										<tr >
											<th scope="col">Name</th>
											<th scope="col">Price</th>
											<th scope="col">Returnable Quantity</th>
											<th scope="col">Quantity</th>
											<th scope="col">Sub Total</th>
											<th scope="col">Tax Amount</th>
											<th scope="col">Discount Amount</th>
											<th scope="col">Row Total</th>
										</tr>
									</thead>
									<tbody >
										{data.salesOrder.items.map((value, index) => {
											
											return (
												<tr key={index}>
													<td >
														{value.name}
													</td>
													<td >
														{value.price}
													  </td>
													<td >
														<input name={"item_id_"+value.id} type="text" defaultValue={value.qty_refund} />
													</td>
													<td >
														{value.qty_ordered}
													</td>
													<td >
														{value.sub_total}
													</td>
													<td >
														{value.tax_amount_for_refund}
													</td>
													<td >
														{value.discount_amount_for_refund}
													</td>
													<td >{value.row_total_for_refund}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
						<div className="row justify-content-end pr-3">
							<div className="col-6">
								<table className="refund-subtotal table subtotal-table">
											<tbody>
												<tr>
													<th className="sub-heading" scope="row">Sub-Total</th>
													<td >{data.salesOrder.creditmemo_subtotal}</td>
												</tr>
												<tr>
													<th className="sub-heading" scope="row">Adjustment Refund</th>
													<td ><input name="adjustment_refund" type="text" defaultValue={0} />
													<input type="hidden" name="order_id" defaultValue={props.orderId} />
													</td>
												</tr>
												<tr>
													<th className="sub-heading" scope="row">Adjustment Fee</th>
													<td ><input name="adjustment_fee" type="text" defaultValue={0} /></td>
												</tr>
												<tr>
													<th className="sub-heading" scope="row">Tax</th>
													<td >{data.salesOrder.tax_for_refund}</td>
												</tr>
												<tr>
													<th className="sub-heading" scope="row">Discount</th>
													<td >{data.salesOrder.discount_amount_for_refund}</td>
												</tr>
												<tr>
													<th className="sub-heading total" scope="row">Total</th>
													<td className="total">{data.salesOrder.creditmemo_total}</td>
												</tr>
											</tbody>
										</table>
							</div>
						</div>
						
					</div>
						
					</form>
			);
		}
		
	} else if(loading !== false) {
		return (
			<div>
				<OrderViewSkeleton />
			</div>
		);
	}else{
		return (
		<div className="no-customer-error">
			<img src="images/no-item.png" alt="images" width="40" />
			<p className="no-prduct-refund">{"Order with id "+ props.orderId + " doesn't exist."}</p>
		</div>
		);
	}

}
export default CreateRefund;
