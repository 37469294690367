import React from "react";
import "./InvoicePrint.css";
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import OrderViewSkeleton from "./OrderViewSkeleton";

const ORDERS_QUERY = gql`
			query getOrderDetails($id: Int) {
				salesOrder(id: $id) {
					increment_id
                    customer_name
                    discount_amount
					grand_total
					created_at
					invoice_id
					subtotal
					telephone
					payment
					tax
					items{
						name
						price
						qty_ordered
						row_total
					}
				}
			  }
        `;

function OrderView(props) {
    let placedOrderId = localStorage.getItem('orderIdForInvoice');
    let orderData;
    let datavariables = { id: placedOrderId };
    const { data } = useQuery(ORDERS_QUERY, {
        variables: datavariables,
        skip: !placedOrderId,
        fetchPolicy: "network-only"
    });
    if (placedOrderId && typeof data != 'undefined') {
        orderData = data.salesOrder;
    } else {
        orderData = JSON.parse(localStorage.getItem('orderData'));
    }
    let agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
    agentDetails['pos_settings'] = JSON.parse(agentDetails.pos_settings);
    if (orderData && typeof orderData != 'undefined') {
        var posLogo = agentDetails.pos_settings.receipt_logo
        if(!posLogo){
            posLogo = "images/300x300.png"
        }
        return (
            <table class="responsive table-wrapper" align="center" width="300" border="0" cellpadding="0" cellspacing="0">
                {(agentDetails.pos_settings.receipt_show_logo === 1) && (
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadidng="0" border="0" align="center">
                                <tr>
                                    <td>
                                        <img class="responsive-img" src={posLogo} alt="logo" border="0" />
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                )}
                {agentDetails.pos_settings.receipt_top_header_content && (
                    <tr>
                        <td>
                            <table cellspacing="0" cellpadidng="0" border="0" align="center">
                                <tr>
                                    <td className="receipt-header-td" align="center">
                                        <div className="receipt-top-header-content">
                                            {agentDetails.pos_settings.receipt_top_header_content}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                )}
                <tr>
                    <td>
                        <table className="header-info responsive" cellspacing="0" cellpadidng="0" border="0" width="100%">
                            {(agentDetails.pos_settings.receipt_show_outlet_name === 1) && (
                                <tr>
                                    <td className="info-label">
                                        {agentDetails.pos_settings.receipt_outlet_label}{" : "}
                                    </td>
                                    <td className="info-value">
                                        {agentDetails.store_name}
                                    </td>
                                </tr>
                            )}
                            {(agentDetails.pos_settings.receipt_show_pos_agent_name === 1) && (
                                <tr>
                                    <td className="info-label">
                                        {agentDetails.pos_settings.receipt_pos_agent_label}{" : "}
                                    </td>
                                    <td className="info-value">
                                        {agentDetails.name}
                                    </td>
                                </tr>
                            )}
                            {(agentDetails.pos_settings.receipt_show_customer_name === 1) && (
                                <tr>
                                    <td className="info-label">
                                        {agentDetails.pos_settings.receipt_customer_name_label}{" : "}
                                    </td>
                                    <td className="info-value">
                                        {orderData.customer_name}
                                    </td>
                                </tr>
                            )}
                            {(agentDetails.pos_settings.receipt_show_order_date === 1) && (
                                <tr>
                                    <td className="info-label">
                                        {agentDetails.pos_settings.receipt_order_date_label}{" : "}
                                    </td>
                                    <td className="info-value">
                                        {orderData.created_at}
                                    </td>
                                </tr>
                            )}
                            {(agentDetails.pos_settings.receipt_show_order_id === 1) && (
                                <tr>
                                    <td className="info-label">
                                        {agentDetails.pos_settings.receipt_order_label}{" : "}
                                    </td>
                                    <td className="info-value">
                                        {orderData.increment_id}
                                    </td>
                                </tr>
                            )}
                            {(agentDetails.pos_settings.receipt_show_invoice_id === 1) && (
                                <tr>
                                    <td className="info-label">
                                        {agentDetails.pos_settings.receipt_invoice_label}{" : "}
                                    </td>
                                    <td className="info-value">
                                        {orderData.invoice_id}
                                    </td>
                                </tr>
                            )}
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table className="products-table responsive" cellspacing="0" cellpadidng="0" border="0" width="100%">
                            <thead>
                                <tr>
                                    <th className="table-title">Item(s)</th>
                                    <th className="table-title">Price</th>
                                    <th className="table-title">Qty</th>
                                    <th className="table-title">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData.items.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="table-value">{value.name}</td>
                                            <td className="table-value">{value.price}</td>
                                            <td className="table-value">{value.qty_ordered}</td>
                                            <td className="table-value">{value.row_total}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table class="responsive header-info" width="100%" cellspacing="0" cellpadidng="0" border="0">
                            <tr>
                            <td className="subtotal-label">{agentDetails.pos_settings.subtotal_label}</td>
                                <td className="subtotal-value">{orderData.subtotal}</td>
                            </tr>
                            <tr>
                                <td className="subtotal-label">{agentDetails.pos_settings.discount_label}</td>
                                <td className="subtotal-value">{orderData.discount_amount}</td>
                            </tr>
                            <tr>
                                <td className="subtotal-label">{agentDetails.pos_settings.tax_label}</td>
                                <td className="subtotal-value">{orderData.tax}</td>
                            </tr>
                            <tr>
                                <td className="total-label">{agentDetails.pos_settings.grand_total_label}</td>
                                <td className="total-value">{orderData.grand_total}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                {agentDetails.pos_settings.receipt_footer_content && (
                    <tr>
                        <td>
                            <table className="responsive thank-you-section">
                                <tr>
                                    <td className="thank-you-content">
                                        {agentDetails.pos_settings.receipt_footer_content}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                )}
            </table>
        );
    } else {
        return (
            <div>
                <OrderViewSkeleton />
            </div>
        );
    }

}
export default OrderView;
