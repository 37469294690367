import React, { useState } from "react";
import "../Pos/pos.css";
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import CategoryFilter from "../CategoryFilter/CategoryFilter";
import Search from "../Search/Search";
import Skelton from "../Skelton/Skelton";
import Loader from "../Loader/Loader";
import { Store } from '../../Store';
import { useProduct } from '../../talons/useCustom'
import { Scrollbars } from 'react-custom-scrollbars';
import { useAlert } from "react-alert";

const PRODUCTS = gql`
			query getLatestProduct($category_id: String!, $store_id: String!) {
				latestProduct(category_id: $category_id, store_id: $store_id) {
					data {
						name
						price
						final_price
						sku
						image_url
						stock_qty
					}
				}
			}
		`;

let searchQuery = gql`
  query getSearchProduct($search_keyword: String!, $is_barcode: String!, $store_id: String!) {
    getSearchProduct(search_keyword: $search_keyword, is_barcode:$is_barcode, store_id: $store_id) {
		data {
			name
			price
			final_price
			sku
			image_url
			stock_qty
		}
    }
  }
`;

function Products(props) {
	 
	const alert = useAlert();
	const { addProductToCart } = useProduct();
	const { state,dispatch } = React.useContext(Store);
	
	const [searchValue, setSearchValue] = useState("");
	const [refreshPage, setRefreshPage] = useState(false);
	const [isBarcodeSearch, setIsBarcodeSearch] = useState("");
	const [filterValue, setFilterValue] = useState("");
	console.log(state);
	if((state.checkoutSuccess && !refreshPage) || (state.productUpdate && !refreshPage)){
		
		setRefreshPage(true);
		dispatch({
            type: 'product_update',
            payload: false
        });
	}
	let query = PRODUCTS;
	let storeId = localStorage.getItem('store_id');
	let datavariables = { category_id: 0, store_id: storeId };
	if (filterValue) {
		query = PRODUCTS;
		datavariables = { category_id: filterValue, store_id: storeId };
	}

	if (searchValue) {
		query = searchQuery;
		datavariables = { search_keyword: searchValue, is_barcode: isBarcodeSearch, store_id: storeId };
	}
	
	const { loading, error, data,refetch } = useQuery(query, {
		variables: datavariables,
		fetchPolicy: 'network-only'

	});
	if(state.productUpdate == true || state.checkOutSuccess == true){
		refetch();
	}
	const searchCalled = (value, isBarcodeSearch) => {
		setSearchValue(value);
		if (isBarcodeSearch) {
			setIsBarcodeSearch(isBarcodeSearch);
		} else {
			setIsBarcodeSearch(0);
		}
	}
	const filterCalled = (value) => {
		setFilterValue(value);
	}

	const addToCart = (value) => {
		
		if (!localStorage.getItem('customerId')) {
			alert.error("Please select customer first");
			return false;
		}
		var items = [];
		var data = [];
		addProductToCart({ 'sku': value.sku });
	}

	let agentDetails = JSON.parse(localStorage.getItem('agentDetails'));
	let limit;
	if(agentDetails != null){
		agentDetails['pos_settings'] = JSON.parse(agentDetails.pos_settings);
		limit = agentDetails['pos_settings'].low_stock_qty;
	}

	function showProductData(){
		if (loading) {
			return(
				<Scrollbars>
					<Skelton />
				</Scrollbars>
			);
		}
		if (searchValue && data.getSearchProduct) {
			if (data && data.getSearchProduct.data) {
				return (
					<Scrollbars className="custom-scroll">
						<div className="content-wrapper product-tiles-wrapper d-flex flex-wrap">
							{data && data.getSearchProduct.data.map((value, index) => {
								var imageUrl = value.image_url;
								if(imageUrl.indexOf("image.jpg") != -1){
									imageUrl = "images/212X263.png";
								}
								var product_class = "product-box";
								if(value.stock_qty == 0){
									product_class = "product-box out-of-stock";
								}else if(value.stock_qty <= limit ){
									product_class = "product-box low-stock";
								}
								return (
									<a onClick={() => addToCart(value)} key={index} className={product_class}>
										<div className="img-wrap">
											<img className="img-fluid" alt={value.name} src={imageUrl} />
										</div>
										<div className="product-info">
											<p className="product-name mb-1">{value.name}</p>
											<p className="product-sku mb-1">{"Sku : " +  value.sku}</p>
											<p className="product-price mb-1">
												{value.price}
											</p>
											<p className="stock-info instock">
												<span className="stock-name">In Stock:</span>
												<span className="stock-count">{value.stock_qty}</span>
											</p>
										</div>
									</a>
									
								);
							})}
						</div>
					</Scrollbars>
				);
			} else {
				return (
					<div className="noproduct-wrapper d-flex flex-wrap justify-content-center">
						<img className="mb-4" alt="no result found" src="images/search(3).png" width="60" height="60" />
						<p className="no-product">Sorry, we did not find any product.</p>
					</div>
				);
			}
		} else {
			if (data && data.latestProduct.data) {
				
				return (
					<div className="left-wrapper">
						{state.showLoader && (
							<Loader />
						)}
						<Scrollbars className="custom-scroll">
							<div className="content-wrapper product-tiles-wrapper d-flex flex-wrap">
								{data && data.latestProduct.data.map((value, index) => {
									var imageUrl = value.image_url;
									if(imageUrl.indexOf("image.jpg") != -1){
										imageUrl = "images/212X263.png";
									}
									var product_class = "product-box";
									if(value.stock_qty == 0){
										product_class = "product-box out-of-stock";
									}else if(value.stock_qty <= limit ){
										product_class = "product-box low-stock";
									}
									return (
										
										<a onClick={() => addToCart(value)} key={index} className={product_class}>
											<div className="img-wrap">
												<img className="img-fluid" alt={value.name} src={imageUrl} />
											</div>
											<div className="product-info">
												<p className="product-name mb-1">{value.name}</p>
												<p className="product-sku mb-1">{"Sku : " + value.sku}</p>
												<p className="product-price mb-1">
													{value.price}
												</p>
												<p className="stock-info instock">
													<span className="stock-name">In Stock:</span>
													<span className="stock-count">{value.stock_qty}</span>
												</p>
											</div>
										</a>
									
									);
								})}
							</div>
						</Scrollbars>
					</div>
				);
			} else {
				return (
					<div className="noproduct-wrapper d-flex flex-wrap justify-content-center">
						<img className="mb-4" alt="no result found" src="images/search(3).png" width="60" height="60" />
						<p className="no-product">Sorry, we did not find any product.</p>
					</div>
				);
			}
		}
	}
	// if (loading) {
	// 	return (
	// 		<div className="left-content-wrapper">
	// 			<Search history={props.history} onChange={searchCalled} />
	// 			<CategoryFilter history={props.history} onChange={filterCalled} />
	// 			<Scrollbars>
	// 				<Skelton />
	// 			</Scrollbars>
	// 		</div>
	// 	);
	// }
	return (
		<div className="left-section-wrapper">
			<Search history={props.history} onChange={searchCalled} />
			<CategoryFilter history={props.history} onChange={filterCalled} />
			{showProductData()}
		</div>
	);
}


export default Products;
