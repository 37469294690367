import React, {useState} from "react";
import "../Pos/pos.css";
import Orders from "../Orders/Orders";
import AddInventory from "../AddInventory/AddInventory";
function Navigation(props) {
	//this.node = React.createRef();
	const [showOrder, setShowOrder] = useState(false);
	const [addInventory, setAddInventory] = useState(false);
	// if(props.isAuthenticated === false){
	//   props.history.push("/");
	// }


	// function closeSidebar(){
	//         setActiveToggle(!activeToggle);
	//     }


	const closePopup =(e) => {
        props.onChange(true);
	}
	
	function toggleOrderList(){
        setShowOrder(!showOrder);
	}
	
	function toggleAddInventory(){
        setAddInventory(!addInventory);
    }

	return (
		<div className="navigation_wrapper">
			{showOrder && (
				<Orders onChange={toggleOrderList}/>
			)}
			{addInventory && (
				<AddInventory onChange={toggleAddInventory}/>
			)}
			<nav className={showOrder ? "side-bar-navigation order-table-overlay" : "side-bar-navigation"} id="sidebar" >

				<div className="sidebar-header">
					<span className="side-panel-title">Side Panel</span>
					<a href="#" onClick={closePopup} className="close-side" id="close-sidebar">x</a>
				</div>
				<ul className="list-unstyled components">
					{/* <li className="side-nav-list active">
					<a className="side-nav-link" href="/#">Sales</a>
				</li>
				<li className="side-nav-list">
					<a className="side-nav-link" href="/#">Products Report</a>
				</li> */}
					<li className="side-nav-list">
						<a className="side-nav-link" onClick={(e) => setShowOrder(true)} href="#">All Orders</a>
					</li>
					<li className="side-nav-list">
						<a className="side-nav-link" onClick={(e) => setAddInventory(true)} href="#">Add Inventory</a>
					</li>
					{/* <li className="side-nav-list">
					<a className="side-nav-link" href="/#">All Returns</a>
				</li> */}
				</ul>
			</nav>
		</div>
	);
}
export default Navigation;
