import React from "react";
import "./pos.css";
import Navigation from "../Navigation/Navigation";
import Cart from "../Cart/Cart";
import Products from "../Products/Products";

function Pos(props) {
  if(!localStorage.getItem("userHasAuthenticated")){
    props.history.push("/");
  }

  
//const [loading, error, data] = useQuery(products_query);
//console.log(data);
  return (
  	<div className="dashboard-design-page wrapper">
		<Navigation />
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-8 col-md-7 col-sm-12 p-0">
						<Products history={props.history}/>
					</div>
					<div className="col-lg-4 col-md-5 col-sm-12 p-0 d-lg-block position-relative">
						<Cart history={props.history}/>
					</div>
				</div>			
			</div>
	</div>
  );
}
export default Pos;
