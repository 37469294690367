import React, { useState } from "react";
import "./login.css";
import "../index.css";
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useAlert } from "react-alert";

export default function Login(props) {
  if (localStorage.getItem("userHasAuthenticated")) {
    props.history.push("/pos");
  }
  const alert = useAlert();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logIn, setLogIn] = useState(false);
  const AGENTLOGIN_MUTATION = gql`
  mutation agentLogin($username: String!, $password: String!) {
    agentlogin(username: $username, password: $password) {
      success,
      id,
      name,
      email,
      contact_number,
      country,
      region,
      region_id,
      city,
      street,
      pincode,
      profile_picture,
      store_id,
      store_name,
      store_country,
      store_region_id,
      store_region,
      store_street,
      store_city,
      store_pincode,
      currency_code,
      currency_symbol,
      cashondelivery,
      paybycard,
      cashondelivery_title,
      paybycard_title,
      paybycard_services,
      pos_settings
    }
  }
  `;
  const [agentLogin, {loading, data }] = useMutation(AGENTLOGIN_MUTATION);
  async function handleSubmit() {
    await agentLogin({ variables: { username: email, password: password } });
    setLogIn(true);
  }
  async function handleSubmitByEnter(e) {
    if (e.key === 'Enter') {
      await agentLogin({ variables: { username: email, password: password } });
      setLogIn(true);
    }
  }
  if (typeof data != "undefined") {
    if (data.agentlogin.success === "true" && logIn === true) {
      props.userHasAuthenticated(true);
      localStorage.setItem("userHasAuthenticated", true);
      localStorage.setItem("agentName", data.agentlogin.name);
      localStorage.setItem("agentId", data.agentlogin.id);
      localStorage.setItem("currency_code", data.agentlogin.currency_code);
      localStorage.setItem("currency_symbol", data.agentlogin.currency_symbol);
      localStorage.setItem("store_id", data.agentlogin.store_id);
      localStorage.setItem("agentDetails", JSON.stringify(data.agentlogin));
      props.history.push('/pos');
    }
    if (data.agentlogin.success === "false" && logIn === true) {
      setLogIn(false);
      alert.error("Username or password is wrong.");
    }
  }



  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  return (
    <div className="login-form-wrapper">
      <div className="login-form">
        <form className="ng-pristine ng-invalid ng-touched" noValidate="">
          <h1 className="title login-title">login to continue!</h1>
          <p className="sub-title">Please enter your Email/Contact Number and Password to login</p>
          <div className="input-field email-field">
            <input aria-label="email" autoFocus="" value={email} onChange={e => setEmail(e.target.value)} placeholder="E-mail" onKeyDown={handleSubmitByEnter} type="email" className="ng-pristine ng-invalid error ng-touched" />
          </div>
          <div className="input-field password-field">
            <input aria-label="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} onKeyDown={handleSubmitByEnter} type="password" className="ng-untouched ng-pristine ng-invalid" />
          </div>
          
          <div className="action">
          
            <button className="submit-btn form-btn position-relative" type="button" onClick={handleSubmit} onKeyDown={handleSubmitByEnter}  disabled={!validateForm()}>
              <span className="login-text">Log in</span>
              {loading && (
                <div className="ripple"></div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
