import React from 'react';

export const Store = React.createContext();


const initialState = {
  episodes: [],
  favourites: [],
  cartItems:[],
  showLoader:false,
  checkOutSuccess:false,
  productUpdate:false
};

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_DATA':
      return { ...state, episodes: action.payload };
    case 'cart_items':
      return { ...state, cartItems: action.payload };
    case 'show_loader':
      return { ...state, showLoader: action.payload };
    case 'checkout_success':
      return { ...state, checkOutSuccess: action.payload };
    case 'product_update':
      return { ...state, productUpdate: action.payload };
    case 'customer_update':
      return { ...state, customerUpdate: action.payload }; 
    default:
      return state;
  }
}
export function StoreProvider(props) {

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const value = {
    state: state,
    dispatch: dispatch
  }
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}