import React, { useState } from "react";
import "./itemDetails.css";
import "./AddInventory.css";
import InventorySkeleton from './inventorySkeleton';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { useUpdateQty } from '../../talons/useCustom';
import { useAlert } from "react-alert";
import { Store } from '../../Store';

const INVENTORY_QUERY = gql`
			query addInventory($search: String) {
				addInventory(search: $search) {
					product_id
					product_name
					product_qty
				}
			  }
		`;

function ItemDetails(props) {
	const alert = useAlert();
	let datavariables = { search: props.searchKey };
	const [qty, setQty] = useState("");
	const { error, data } = useQuery(INVENTORY_QUERY, {
		variables: datavariables,
		fetchPolicy: "network-only"
	});
	const { state, dispatch } = React.useContext(Store);
	const { updateQty,productData,qtyUpdateError } = useUpdateQty();

	const handleUpdateQty = async () =>{       
		await updateQty({product_id:data.addInventory.product_id,product_qty:qty});
		dispatch({
            type: 'product_update',
            payload: true
        });
	}

	if(typeof qtyUpdateError !="undefined"){
        alert.qtyUpdateError(error.graphQLErrors[0].message);
	}
	
	if(typeof productData !="undefined"){
		console.log(productData.success);
        if(productData.success){
			props.onChange("");
            alert.success('Qty Updated Successfully.');
        }else{
            alert.error('Something went wrong while updating qty.');
        }
       
	}
	
	if (typeof data != 'undefined') {
		if (data && data.addInventory.product_id) {
			return (
				<div className="inventory-details-form">
					<div className="inv-pid">
						<span className="inv-label">Product ID :</span>
						<span className="inv-value">{data.addInventory.product_id}</span>
					</div>
					<div className="inv-pname">
					<span className="inv-label">Product Name :</span>
						<span className="inv-value">{data.addInventory.product_name}</span>
					</div>
					<div className="inv-pqty">
					<span className="inv-label">Product Qty :</span>
					<div className="inv-input-wrap">
						<input className="inv-qty-input" onChange={(e) => setQty(e.target.value)} defaultValue={data.addInventory.product_qty}/>
					</div>
					</div>
					<button className="secondary-btn update-btn" type="button" onClick={() => handleUpdateQty()}>Update</button>
				</div>
			);
		} else {
			return (
				<div className="noproduct-wrapper d-flex flex-wrap justify-content-center">
					<img className="mb-4" alt="no result found" src="images/search(3).png" width="60" height="60" />
					<p className="no-product">Sorry, we did not find any product.</p>
				</div>
			);
		}
	} else {
		return (
			<div>
				<InventorySkeleton />

			</div>
		);
	}
}
export default ItemDetails;
